import { toString, Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Request_UpdatePriority, Response_Model, Response_Model_$reflection } from "../StenaWeather.WeatherServiceUI.Shared/Models/API.js";
import { record_type, string_type, option_type, unit_type, int32_type, bool_type, union_type, list_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ServerError_$reflection } from "../StenaWeather.WeatherServiceUI.Shared/Errors.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { Point, Point_$reflection } from "../StenaWeather.Domain/Forecast.js";
import { ModelUpdateStatus, ModelAreaModule_isPointWithin, ModelKey, Models_all, ModelArea_$reflection } from "../StenaWeather.Domain/Models.js";
import { isEmpty, iterateIndexed, mapIndexed, sortBy, cons, filter, exists, map, singleton, empty, item, length, indexed, tryFind, ofArray } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { value as value_50, map as map_1, defaultArg, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { WeatherTypeModule_Parameter_$reflection } from "../StenaWeather.Domain/Weather.js";
import { PageTitle_title, ErrorView_Cmd_ofError } from "../SharedView.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { noCmd } from "../UseElmish.js";
import { reorder } from "../List.js";
import { defaultOf, curry2, int32ToString, createObj, equals, comparePrimitives } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { onModelsAPI, Cmd_OfAsync_eitherAsResult } from "../Server.js";
import { ofList } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { Polygon_View } from "../Components/Leaflet/Polygon.js";
import { Rectangle_View } from "../Components/Leaflet/Rectangle.js";
import Models$002Emodule from "../../src/StenaWeather.WeatherServiceUI.Client/Pages/Models.module.scss";
import { printf, toText, replace, join } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { IconModule_View } from "../Components/StenaWebUI/Icons/Icon.js";
import { faCaretDown, faCaretUp, faGripVertical, faCircle } from "@fortawesome/free-solid-svg-icons";
import { TextModule_View } from "../Components/StenaWebUI/Core/Text.js";
import { WhiteSpaceType, UserSelectType, TextSize, TextVariant } from "../Components/StenaWebUI/Core/Text.js";
import { toArray, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { rangeDouble } from "../fable_modules/fable-library-js.4.19.3/Range.js";
import { ButtonBase_ViewSecondary, ButtonBase_ViewPrimary, ButtonBase_ViewFlat } from "../Components/StenaWebUI/Elements/Button.js";
import { ButtonSize } from "../Components/StenaWebUI/Elements/Button.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { LinkModule_View } from "../Components/StenaWebUI/Elements/Link.js";
import { StandardTableModule_view } from "../Components/StenaWebUI/Grid/StandardTable.js";
import { formatToWholeUnits } from "../TimeSpan.js";
import { utcNow, op_Subtraction } from "../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { CheckboxWithLabelModule_View } from "../Components/StenaWebUI/Elements/CheckBox.js";
import { SwitchWithLabelModule_View } from "../Components/StenaWebUI/Forms/SwitchWithLabel.js";
import { MapEvents_View } from "../Components/Leaflet/MapEvents.js";
import { TileLayer } from "react-leaflet";
import { Popup_View } from "../Components/Leaflet/Popup.js";
import { LegendPosition } from "../Components/Leaflet/Legend.js";
import { Legend_View } from "../Components/Leaflet/Legend.js";
import { MapContainer_View } from "../Components/Leaflet/Container.js";

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoadModels", "OnModelsLoaded", "OnModelToggled", "ToggleMap", "ModelPriorityChanged", "SaveModelPriorities", "OnPrioritiesSaved", "SetModelCoveragePoint"];
    }
}

export function Msg_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.Models.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Response_Model_$reflection()), ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(Response_Model_$reflection())]], [["ErrorValue", ServerError_$reflection()]]])]], [["Item", Response_Model_$reflection()]], [["Item", bool_type]], [["oldIndex", int32_type], ["newIndex", int32_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ServerError_$reflection()]]])]], [["Item", option_type(Point_$reflection())]]]);
}

export class MapModel extends Record {
    constructor(Color, Name, Label, Area) {
        super();
        this.Color = Color;
        this.Name = Name;
        this.Label = Label;
        this.Area = Area;
    }
}

export function MapModel_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.Models.MapModel", [], MapModel, () => [["Color", string_type], ["Name", string_type], ["Label", string_type], ["Area", ModelArea_$reflection()]]);
}

export function getColor(name) {
    const colors = ofArray(["#bfffa5", "#ea6776", "#fc64a9", "#69178c", "#dce587", "#8aa01b", "#f72211", "#6773fc", "#f2af68", "#2175f2", "#fcc90f", "#0066af", "#dfff77", "#fcc4fa", "#9274d3", "#dd46bd", "#dd46be", "#bfffa9", "#fc64af", "#bffdb5", "#5772bc", "#40b5ad"]);
    const matchValue = tryFind((tupledArg) => (tupledArg[1].Name === name), indexed(Models_all));
    if (matchValue != null) {
        if (matchValue[0] < length(colors)) {
            const i_1 = matchValue[0] | 0;
            return item(i_1, colors);
        }
        else {
            const i_2 = matchValue[0] | 0;
            console.warn(some("getColor: index too big"), i_2);
            return "#aaaaaa";
        }
    }
    else {
        console.warn(some("getColor: name not found"), name);
        return "#aaaaaa";
    }
}

export function MapModelModule_ofModel(m) {
    return new MapModel(getColor(m.ModelName), m.ModelName, `${m.ModelName} (${m.Provider})`, m.Area);
}

export class State extends Record {
    constructor(Parameter, Models, MapModels, ExpandMap, ModelsReordered, ModelCoveragePoint) {
        super();
        this.Parameter = Parameter;
        this.Models = Models;
        this.MapModels = MapModels;
        this.ExpandMap = ExpandMap;
        this.ModelsReordered = ModelsReordered;
        this.ModelCoveragePoint = ModelCoveragePoint;
    }
}

export function State_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.Models.State", [], State, () => [["Parameter", WeatherTypeModule_Parameter_$reflection()], ["Models", list_type(Response_Model_$reflection())], ["MapModels", list_type(MapModel_$reflection())], ["ExpandMap", bool_type], ["ModelsReordered", bool_type], ["ModelCoveragePoint", option_type(Point_$reflection())]]);
}

export function init(p, unitVar) {
    return [new State(p, empty(), empty(), true, false, undefined), singleton((dispatch) => {
        dispatch(new Msg(0, []));
    })];
}

export function update(msg, state) {
    let x_3;
    switch (msg.tag) {
        case 1: {
            const models = msg.fields[0];
            if (models.tag === 1) {
                return [state, ErrorView_Cmd_ofError(models.fields[0])];
            }
            else {
                const models_1 = models.fields[0];
                return [new State(state.Parameter, models_1, map(MapModelModule_ofModel, models_1), state.ExpandMap, false, state.ModelCoveragePoint), Cmd_none()];
            }
        }
        case 2: {
            const model = msg.fields[0];
            return [new State(state.Parameter, state.Models, exists((x_1) => (x_1.Name === model.ModelName), state.MapModels) ? filter((x_2) => (x_2.Name !== model.ModelName), state.MapModels) : cons(MapModelModule_ofModel(model), state.MapModels), state.ExpandMap, state.ModelsReordered, state.ModelCoveragePoint), Cmd_none()];
        }
        case 3:
            return noCmd(new State(state.Parameter, state.Models, state.MapModels, msg.fields[0], state.ModelsReordered, state.ModelCoveragePoint));
        case 4: {
            const newModels = sortBy((x_5) => x_5.Priority, mapIndexed((i, x_4) => (new Response_Model(i, x_4.DataSourceDescription, x_4.Provider, x_4.ModelName, x_4.SpatialResolution, x_4.SpatialResolutionInKm, x_4.TemporalResolution, x_4.Area, x_4.TemporalRange, x_4.UpdateFrequency, x_4.LastUpdated, x_4.SourceType, x_4.IsRotated, x_4.Status)), (x_3 = reorder(msg.fields[0], msg.fields[1], state.Models), (console.log(some(x_3)), x_3))), {
                Compare: comparePrimitives,
            });
            return noCmd(new State(state.Parameter, newModels, state.MapModels, state.ExpandMap, state.ModelsReordered ? true : !equals(state.Models, newModels), state.ModelCoveragePoint));
        }
        case 5: {
            const reqData = new Request_UpdatePriority(state.Parameter, map((x_7) => (new ModelKey(x_7.Provider, x_7.ModelName)), state.Models));
            return [state, Cmd_OfAsync_eitherAsResult(() => onModelsAPI((x_8) => x_8.SavePriorities(reqData)), (Item_1) => (new Msg(6, [Item_1])))];
        }
        case 6: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return [state, ErrorView_Cmd_ofError(res.fields[0])];
            }
            else {
                return [state, singleton((dispatch) => {
                    dispatch(new Msg(0, []));
                })];
            }
        }
        case 7:
            return noCmd(new State(state.Parameter, state.Models, state.MapModels, state.ExpandMap, state.ModelsReordered, msg.fields[0]));
        default:
            return [state, Cmd_OfAsync_eitherAsResult(() => onModelsAPI((x) => x.GetModels(state.Parameter)), (Item) => (new Msg(1, [Item])))];
    }
}

function modelRectangle(m) {
    let area;
    const matchValue = m.Area;
    if (matchValue.tag === 1) {
        const p_1_2 = ofList(map((value_1) => value_1, ofArray([["fill", true], ["color", m.Color], ["fillOpacity", 0.15], ["points", matchValue.fields[0]]])), {
            Compare: comparePrimitives,
        });
        return createElement(Polygon_View, {
            p: p_1_2,
        });
    }
    else {
        const p_1_1 = ofList(map((value) => value, ofArray([(area = matchValue.fields[0], ["bounds", [new Point(area.West, area.North), new Point(area.East, area.South)]]), ["color", m.Color], ["fillOpacity", 0.15]])), {
            Compare: comparePrimitives,
        });
        return createElement(Rectangle_View, {
            p: p_1_1,
        });
    }
}

export const cn = (() => {
    const styles = Models$002Emodule;
    const cnFn = (name) => (styles[name]);
    const cnFns = (names) => map((x) => (styles[x]), names);
    return {
        className: cnFn,
        classes: cnFns,
        prop: {
            className: (arg) => ["className", cnFn(arg)],
            classes: (arg_1) => ["className", join(" ", cnFns(arg_1))],
        },
    };
})();

export function ModelUpdateStatus_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "To be updated";
        case 2:
            return "Outdated";
        default:
            return "Up to date";
    }
}

function modelLegend(m) {
    let elements;
    const elm = ofArray([createElement("svg", createObj(singleton((elements = singleton(createElement("rect", {
        fill: m.Color,
    })), ["children", reactApi.Children.toArray(Array.from(elements))])))), createElement("span", {
        children: [m.Name],
    })]);
    return createElement("div", {
        className: cn.className("legend-item"),
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

export class uiObj extends Record {
    constructor(item) {
        super();
        this.item = item;
    }
}

export function uiObj_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.Models.uiObj", [], uiObj, () => [["item", Response_Model_$reflection()]]);
}

export function renderCellForStatus(f) {
    let t_4, t_7, t_1;
    let icon;
    const matchValue = f.item.Status;
    icon = ((matchValue.tag === 1) ? IconModule_View(ofList(map((value_1) => value_1, ofArray([["size", 10], (t_4 = faCircle, ["icon", t_4]), ["color", "var(--lhds-color-orange-500)"]])), {
        Compare: comparePrimitives,
    })) : ((matchValue.tag === 2) ? IconModule_View(ofList(map((value_2) => value_2, ofArray([["size", 10], (t_7 = faCircle, ["icon", t_7]), ["color", "var(--lhds-color-red-500)"]])), {
        Compare: comparePrimitives,
    })) : IconModule_View(ofList(map((value) => value, ofArray([["size", 10], (t_1 = faCircle, ["icon", t_1]), ["color", "var(--lhds-color-green-500)"]])), {
        Compare: comparePrimitives,
    }))));
    const text = TextModule_View(ofList(map((value_4) => value_4, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(1, [])], ["color", "var(--lhds-color-ui-500)"], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", ModelUpdateStatus_value(f.item.Status)]])), {
        Compare: comparePrimitives,
    }));
    return createElement("div", {
        className: cn.className("status"),
        children: reactApi.Children.toArray([icon, text]),
    });
}

export function getHeader(s) {
    switch (s.tag) {
        case 0:
            return "Wind";
        case 2:
            return "Current";
        case 3:
            return "Waves";
        case 4:
            return "Tide (Sea Surface Height)";
        case 5:
            return "Primary Swell";
        case 6:
            return "Seconday Swell";
        case 7:
            return "Wind Waves";
        case 8:
            return "Clouds";
        case 9:
            return "Air Temperature";
        case 10:
            return "Precipitation";
        default:
            return "";
    }
}

export function findParentElm(name_mut, e_mut) {
    findParentElm:
    while (true) {
        const name = name_mut, e = e_mut;
        if (e.parentElement.tagName === name) {
            return e.parentElement;
        }
        else {
            name_mut = name;
            e_mut = e.parentElement;
            continue findParentElm;
        }
        break;
    }
}

export function hasDroppableClass(el) {
    return el.className.indexOf("dragover") >= 0;
}

export function addDroppableClass(el) {
    if (!hasDroppableClass(el)) {
        el.className = (el.className + " dragover");
    }
}

export function removeDroppableClass(el) {
    el.className = replace(el.className, " dragover", "");
}

export function setDroppable(onDrop, index, el) {
    const getTr = (x) => findParentElm("TR", x.target);
    el.ondragenter = ((x_1) => {
        x_1.preventDefault();
        addDroppableClass(getTr(x_1));
    });
    el.ondragleave = ((x_2) => {
        const elm = getTr(x_2);
        const rect = elm.getBoundingClientRect();
        if ((((x_2.clientY < rect.top) ? true : (x_2.clientY >= rect.bottom)) ? true : (x_2.clientX < rect.left)) ? true : (x_2.clientX >= rect.right)) {
            removeDroppableClass(elm);
        }
        else {
            addDroppableClass(elm);
        }
    });
    el.ondragover = ((x_3) => {
        const elm_1 = getTr(x_3);
        if (elm_1.className.indexOf("dragover") >= 0) {
            x_3.preventDefault();
        }
    });
    el.ondrop = ((x_4) => {
        x_4.preventDefault();
        const elm_2 = getTr(x_4);
        if (hasDroppableClass(elm_2)) {
            onDrop(index);
        }
        removeDroppableClass(elm_2);
    });
}

export function RenderDraggableIcon(dispatch, o) {
    let elems, t_1;
    return createElement("div", createObj(ofArray([["draggable", true], ["onDragEnd", (x) => {
        const tbody = findParentElm("TBODY", x.currentTarget);
        tbody.className = "";
    }], ["onDragStart", (x_1) => {
        const tr = findParentElm("TR", x_1.currentTarget);
        const tbody_1 = findParentElm("TBODY", tr);
        if (tbody_1.children.length > 0) {
            iterateIndexed((index, el) => {
                setDroppable((i) => {
                    dispatch(new Msg(4, [o.item.Priority, i]));
                }, index, el);
            }, map((i_1) => (tbody_1.children[i_1]), toList(rangeDouble(0, 1, tbody_1.children.length - 1))));
        }
        tbody_1.className = "droppable";
        const e_3 = x_1.dataTransfer;
        e_3.setDragImage(tr,0,20);
        x_1.dataTransfer.effectAllowed = "move";
    }], ["style", {
        cursor: "move",
    }], (elems = [IconModule_View(ofList(map((value_6) => value_6, ofArray([["size", 20], (t_1 = faGripVertical, ["icon", t_1]), ["color", "var(--lhds-color-ui-400)"]])), {
        Compare: comparePrimitives,
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

export function RenderEditablePriorityCell(dispatch, itemCount, o) {
    let elm_4;
    const changePriority = (i, unitVar) => dispatch(new Msg(4, [o.item.Priority, i]));
    const maxPriority = (itemCount - 1) | 0;
    const elm = ofArray([createElement("div", {
        className: cn.className("priority-label"),
        children: int32ToString(o.item.Priority + 1),
    }), (elm_4 = ofArray([ButtonBase_ViewFlat(ofList(map((value_13) => value_13, toList(delay(() => append(singleton_1(["leftIcon", faCaretUp]), delay(() => append(singleton_1(["size", new ButtonSize(2, [])]), delay(() => {
        let arg;
        return append(singleton_1(["id", (arg = o.item.ModelName, toText(printf("priority-down-%s"))(arg))]), delay(() => append(singleton_1(["onClick", curry2(changePriority)(o.item.Priority - 1)]), delay(() => ((o.item.Priority === 0) ? singleton_1(["disabled", true]) : empty_1())))));
    }))))))), {
        Compare: comparePrimitives,
    })), ButtonBase_ViewFlat(ofList(map((value_14) => value_14, toList(delay(() => append(singleton_1(["leftIcon", faCaretDown]), delay(() => append(singleton_1(["size", new ButtonSize(2, [])]), delay(() => {
        let arg_1;
        return append(singleton_1(["id", (arg_1 = o.item.ModelName, toText(printf("priority-up-%s"))(arg_1))]), delay(() => append(singleton_1(["onClick", curry2(changePriority)(o.item.Priority + 1)]), delay(() => ((o.item.Priority === maxPriority) ? singleton_1(["disabled", true]) : empty_1())))));
    }))))))), {
        Compare: comparePrimitives,
    }))]), createElement("div", {
        className: cn.className("priority-buttons"),
        children: reactApi.Children.toArray(Array.from(elm_4)),
    }))]);
    return createElement("div", {
        className: cn.className("priority-cell"),
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

export function renderModelCell(p) {
    const elm = ofArray([TextModule_View(ofList(map((value_4) => value_4, ofArray([["variant", new TextVariant(3, [])], ["size", new TextSize(0, [])], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", p.item.ModelName]])), {
        Compare: comparePrimitives,
    })), TextModule_View(ofList(map((value_6) => value_6, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(1, [])], ["color", "var(--lhds-color-ui-500)"], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", toString(p.item.Provider)]])), {
        Compare: comparePrimitives,
    }))]);
    return createElement("div", {
        className: cn.className("supplier"),
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

export function renderFullNameCell(p) {
    const elm = singleton(TextModule_View(ofList(map((value_4) => value_4, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(2, [])], ["color", "var(--lhds-color-ui-500)"], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", p.item.DataSourceDescription]])), {
        Compare: comparePrimitives,
    })));
    return createElement("div", {
        className: cn.className("fullName"),
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

export function renderResolution(p) {
    const elm = ofArray([TextModule_View(ofList(map((value_4) => value_4, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(1, [])], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", p.item.SpatialResolution]])), {
        Compare: comparePrimitives,
    })), TextModule_View(ofList(map((value_6) => value_6, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(2, [])], ["color", "var(--lhds-color-ui-500)"], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", p.item.SpatialResolutionInKm]])), {
        Compare: comparePrimitives,
    }))]);
    return createElement("div", {
        className: cn.className("spatialresolution"),
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

function modelsCoveragePopup(mx) {
    let elm;
    if (isEmpty(mx)) {
        return TextModule_View(ofList(map((value_1) => value_1, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(2, [])], ["color", "var(--lhds-color-ui-500)"], ["children", "This area is not covered by any model"]])), {
            Compare: comparePrimitives,
        }));
    }
    else {
        const xs_1 = [TextModule_View(ofList(map((value_5) => value_5, ofArray([["variant", new TextVariant(3, [])], ["size", new TextSize(0, [])], ["children", "Available models:"]])), {
            Compare: comparePrimitives,
        })), (elm = map((txt) => TextModule_View(ofList(map((value_3) => value_3, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(1, [])], ["children", txt]])), {
            Compare: comparePrimitives,
        })), map((x_3) => x_3.ModelName, mx)), createElement("div", {
            className: cn.className("available-models"),
            children: reactApi.Children.toArray(Array.from(elm)),
        }))];
        return react.createElement(react.Fragment, {}, ...xs_1);
    }
}

export function ModelsView(modelsViewInputProps) {
    let elm_2, elm_4, elm_6, elm_14, p_1_6, elems_7, p_1_10;
    const p = modelsViewInputProps.p;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init(p, undefined), update, (_arg, _arg_1) => {
    }), undefined, [p]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const paramName = getHeader(p);
    const coveringModels = sortBy((x) => x.ModelName, defaultArg(map_1((p_1) => filter((m) => ModelAreaModule_isPointWithin(m.Area, p_1), state_1.Models), state_1.ModelCoveragePoint), empty()), {
        Compare: comparePrimitives,
    });
    const elm = ofArray([(elm_2 = ofArray([(elm_4 = ofArray([PageTitle_title(`Weather Service Offer - ${paramName}`, `${paramName} models & geographical coverage`), (elm_6 = toList(delay(() => {
        let p_1_1;
        return state_1.ModelsReordered ? append(singleton_1((p_1_1 = ofList(map((value_14) => value_14, ofArray([["onClick", () => {
            dispatch(new Msg(0, []));
        }], ["id", "discard-priority-changes-button"], ["children", "Discard all changes"]])), {
            Compare: comparePrimitives,
        }), createElement(LinkModule_View, {
            p: p_1_1,
        }))), delay(() => {
            let p_1_2;
            return singleton_1((p_1_2 = ofList(map((value_15) => value_15, ofArray([["label", "Confirm changes"], ["id", "confirm-priority-changes-button"], ["onClick", (_arg_3) => {
                dispatch(new Msg(5, []));
            }]])), {
                Compare: comparePrimitives,
            }), createElement(ButtonBase_ViewPrimary, {
                p: p_1_2,
            })));
        })) : empty_1();
    })), createElement("div", {
        className: cn.className("heading-warning"),
        children: reactApi.Children.toArray(Array.from(elm_6)),
    }))]), createElement("div", {
        className: cn.className("heading-wrapper"),
        children: reactApi.Children.toArray(Array.from(elm_4)),
    })), createElement("div", {
        className: cn.className("table"),
        children: StandardTableModule_view(createObj(toList([["variant", "relaxed"], ["items", toArray(state_1.Models)], ["loading", length(state_1.Models) === 0], ["config", createObj(toList(toList(delay(() => {
            const itemTypeInstance = defaultOf();
            const itemCount = length(state_1.Models) | 0;
            return append(singleton_1(["keyResolver", (i) => i.ModelName]), delay(() => append(singleton_1(["rowBackgroundResolver", (i_1) => {
                if (equals(i_1.Status, new ModelUpdateStatus(2, []))) {
                    return {
                        background: "var(--lhds-color-red-50)",
                    };
                }
                else if (equals(i_1.Status, new ModelUpdateStatus(1, []))) {
                    return {
                        background: "var(--lhds-color-orange-50)",
                    };
                }
                else {
                    return {
                        background: "var(--swui-white)",
                    };
                }
            }]), delay(() => append(singleton_1(["stickyHeader", true]), delay(() => append(singleton_1(["stickyColumnGroups", "first"]), delay(() => append(singleton_1(["disableSorting", true]), delay(() => {
                let columnProps, columnProps_1, columnProps_2, columnProps_3, columnProps_4, columnProps_5, columnProps_6, columnProps_7, columnProps_8, columnProps_9, columnProps_10, columnProps_11, columnProps_12;
                return append(singleton_1(["columns", createObj([(columnProps = createObj(toList([["name", "DGColumn"], ["itemValueResolver", (value_20) => {
                }], ["columnLabel", ""], ["width", toText(`${30}px`)], ["renderCell", (o) => RenderDraggableIcon(dispatch, o)]])), [columnProps.name, columnProps]), (columnProps_1 = createObj(toList([["name", "Priority"], ["itemValueResolver", (i_2) => i_2.Priority], ["width", toText(`${80}px`)], ["renderCell", (o_1) => RenderEditablePriorityCell(dispatch, itemCount, o_1)]])), [columnProps_1.name, columnProps_1]), (columnProps_2 = createObj(toList([["name", "Name"], ["itemValueResolver", (value_21) => {
                }], ["width", toText(`${155}px`)], ["columnLabel", "Model/Supplier"], ["renderCell", renderModelCell]])), [columnProps_2.name, columnProps_2]), (columnProps_3 = createObj(toList([["name", "DataSource"], ["itemValueResolver", (value_22) => {
                }], ["borderLeft", true], ["width", toText(`${158}px`)], ["columnLabel", "Full name"], ["renderCell", renderFullNameCell]])), [columnProps_3.name, columnProps_3]), (columnProps_4 = createObj(toList([["name", "Status"], ["itemValueResolver", (i_3) => i_3.Status], ["width", toText(`${145}px`)], ["renderCell", renderCellForStatus]])), [columnProps_4.name, columnProps_4]), (columnProps_5 = createObj(toList([["name", "LastUpdated"], ["itemValueResolver", (i_4) => {
                    const matchValue = i_4.LastUpdated;
                    if (matchValue != null) {
                        const u = matchValue;
                        return `${formatToWholeUnits(op_Subtraction(utcNow(), u))} ago`;
                    }
                    else {
                        return "Not available";
                    }
                }], ["borderLeft", true], ["width", toText(`${145}px`)]])), [columnProps_5.name, columnProps_5]), (columnProps_6 = createObj(toList([["name", "SpatialResolution"], ["itemValueResolver", (i_5) => i_5.SpatialResolution], ["borderLeft", true], ["width", toText(`${145}px`)], ["renderCell", renderResolution]])), [columnProps_6.name, columnProps_6]), (columnProps_7 = createObj(toList([["name", "TemporalResolution"], ["itemValueResolver", (i_6) => formatToWholeUnits(i_6.TemporalResolution)], ["borderLeft", true], ["width", toText(`${130}px`)]])), [columnProps_7.name, columnProps_7]), (columnProps_8 = createObj(toList([["name", "TemporalRange"], ["itemValueResolver", (i_7) => formatToWholeUnits(i_7.TemporalRange)], ["borderLeft", true], ["width", toText(`${130}px`)]])), [columnProps_8.name, columnProps_8]), (columnProps_9 = createObj(toList([["name", "UpdateFrequency"], ["itemValueResolver", (i_8) => formatToWholeUnits(i_8.UpdateFrequency)], ["borderLeft", true], ["width", toText(`${145}px`)]])), [columnProps_9.name, columnProps_9]), (columnProps_10 = createObj(toList([["name", "SourceType"], ["itemValueResolver", (i_9) => i_9.SourceType], ["borderLeft", true], ["width", toText(`${100}px`)]])), [columnProps_10.name, columnProps_10]), (columnProps_11 = createObj(toList([["name", "IsRotated"], ["itemValueResolver", (i_10) => i_10.IsRotated], ["borderLeft", true], ["width", toText(`${100}px`)], ["renderCell", (x_4) => {
                    let p_1_3;
                    const elm_10 = singleton((p_1_3 = ofList(map((value_26) => value_26, ofArray([["value", x_4.item.IsRotated], ["label", toString(x_4.item.IsRotated)], ["disabled", true]])), {
                        Compare: comparePrimitives,
                    }), createElement(CheckboxWithLabelModule_View, {
                        p: p_1_3,
                    })));
                    return createElement("div", {
                        className: cn.className("cell"),
                        children: reactApi.Children.toArray(Array.from(elm_10)),
                    });
                }]])), [columnProps_11.name, columnProps_11]), (columnProps_12 = createObj(toList([["name", "Action"], ["itemValueResolver", (value_27) => {
                }], ["borderLeft", true], ["width", toText(`${180}px`)], ["renderCell", (f_1) => {
                    const elm_12 = toList(delay(() => {
                        let p_1_4;
                        const size = new ButtonSize(2, []);
                        const buttonId = `${f_1.item.ModelName}_button`;
                        return exists((x_6) => (x_6.Name === f_1.item.ModelName), state_1.MapModels) ? singleton_1((p_1_4 = ofList(map((value_31) => value_31, ofArray([["id", buttonId], ["size", size], ["label", "Highlight area on map"], ["onClick", (_arg_4) => {
                            dispatch(new Msg(2, [f_1.item]));
                        }]])), {
                            Compare: comparePrimitives,
                        }), createElement(ButtonBase_ViewPrimary, {
                            p: p_1_4,
                        }))) : singleton_1(ButtonBase_ViewSecondary(ofList(map((value_32) => value_32, ofArray([["id", buttonId], ["size", size], ["label", "Highlight area on map"], ["onClick", (_arg_5) => {
                            dispatch(new Msg(2, [f_1.item]));
                        }]])), {
                            Compare: comparePrimitives,
                        })));
                    }));
                    return createElement("div", {
                        className: cn.className("cell"),
                        children: reactApi.Children.toArray(Array.from(elm_12)),
                    });
                }]])), [columnProps_12.name, columnProps_12])])]), delay(() => {
                    let groupProps, groupProps_1;
                    return append(singleton_1(["columnGroups", createObj([(groupProps = createObj(toList([["name", "stickyGroup"], ["columnOrder", ["DGColumn", "Priority", "Name", "Status"]]])), [groupProps.name, groupProps]), (groupProps_1 = createObj(toList([["name", "movingGroup"], ["columnOrder", ["LastUpdated", "SpatialResolution", "TemporalResolution", "TemporalRange", "UpdateFrequency", "SourceType", "IsRotated", "Action", "DataSource"]]])), [groupProps_1.name, groupProps_1])])]), delay(() => singleton_1(["columnGroupOrder", ["stickyGroup", "movingGroup"]])));
                }));
            }))))))))));
        }))))]]))),
    })]), createElement("div", {
        className: cn.className("models-content"),
        children: reactApi.Children.toArray(Array.from(elm_2)),
    })), (elm_14 = singleton((p_1_6 = ofList(map((value_36) => value_36, ofArray([["label", "Expand map view"], ["onValueChange", (x_9) => {
        dispatch(new Msg(3, [x_9]));
    }], ["value", state_1.ExpandMap]])), {
        Compare: comparePrimitives,
    }), createElement(SwitchWithLabelModule_View, {
        p: p_1_6,
    }))), createElement("div", {
        className: cn.className("switch-box"),
        children: reactApi.Children.toArray(Array.from(elm_14)),
    })), createElement("div", createObj(ofArray([["key", `map_${state_1.ExpandMap}`], ["className", cn.className("map")], ["style", {
        height: (state_1.ExpandMap ? 100 : 30) + "vh",
    }], (elems_7 = [(p_1_10 = ofList(map((value_48) => value_48, ofArray([["center", new Point(11.914596749734356, 57.69553343057764)], ["scrollWheelZoom", true], ["zoom", 3], ["children", toList(delay(() => {
        let p_1_7;
        return append(singleton_1((p_1_7 = ofList(map((value_45) => value_45, singleton(["onClick", (arg_1) => {
            dispatch(new Msg(7, [arg_1]));
        }])), {
            Compare: comparePrimitives,
        }), createElement(MapEvents_View, {
            p: p_1_7,
        }))), delay(() => {
            let props_17;
            return append(singleton_1((props_17 = {
                attribution: "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors",
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            }, react.createElement(TileLayer, props_17))), delay(() => append(map(modelRectangle, state_1.MapModels), delay(() => {
                let p_1_8;
                return append((state_1.ModelCoveragePoint != null) ? singleton_1((p_1_8 = ofList(map((value_46) => value_46, ofArray([["onClose", () => {
                    dispatch(new Msg(7, [undefined]));
                }], ["position", value_50(state_1.ModelCoveragePoint)], ["children", singleton(modelsCoveragePopup(coveringModels))]])), {
                    Compare: comparePrimitives,
                }), createElement(Popup_View, {
                    p: p_1_8,
                }))) : empty_1(), delay(() => {
                    let p_1_9;
                    return singleton_1((p_1_9 = ofList(map((value_47) => value_47, ofArray([["position", new LegendPosition(3, [])], ["children", toList(delay(() => map(modelLegend, state_1.MapModels)))]])), {
                        Compare: comparePrimitives,
                    }), createElement(Legend_View, {
                        p: p_1_9,
                    })));
                }));
            }))));
        }));
    }))]])), {
        Compare: comparePrimitives,
    }), createElement(MapContainer_View, {
        p: p_1_10,
    }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))]);
    return createElement("div", {
        className: cn.className("models"),
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

