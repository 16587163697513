import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { WeatherTypeModule_Parameter_$reflection } from "../../StenaWeather.Domain/Weather.js";
import { ModelUpdateStatus_$reflection, ModelKey_$reflection } from "../../StenaWeather.Domain/Models.js";
import { lambda_type, unit_type, float64_type, class_type, string_type, record_type, list_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Provider_$reflection } from "../../StenaWeather.Domain/Forecast.js";

export class Request_UpdatePriority extends Record {
    constructor(Parameter, Models) {
        super();
        this.Parameter = Parameter;
        this.Models = Models;
    }
}

export function Request_UpdatePriority_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Dashboards.API.Request.UpdatePriority", [], Request_UpdatePriority, () => [["Parameter", WeatherTypeModule_Parameter_$reflection()], ["Models", list_type(ModelKey_$reflection())]]);
}

export class Response_Model extends Record {
    constructor(ModelName, Provider, Status, UpdateFrequency, LastUpdated, OutdatedAlertCoefficient) {
        super();
        this.ModelName = ModelName;
        this.Provider = Provider;
        this.Status = Status;
        this.UpdateFrequency = UpdateFrequency;
        this.LastUpdated = LastUpdated;
        this.OutdatedAlertCoefficient = OutdatedAlertCoefficient;
    }
}

export function Response_Model_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Dashboards.API.Response.Model", [], Response_Model, () => [["ModelName", string_type], ["Provider", Provider_$reflection()], ["Status", ModelUpdateStatus_$reflection()], ["UpdateFrequency", class_type("System.TimeSpan")], ["LastUpdated", class_type("System.DateTimeOffset")], ["OutdatedAlertCoefficient", float64_type]]);
}

export class DashboardsAPI extends Record {
    constructor(GetModels) {
        super();
        this.GetModels = GetModels;
    }
}

export function DashboardsAPI_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Dashboards.API.DashboardsAPI", [], DashboardsAPI, () => [["GetModels", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(Response_Model_$reflection())]))]]);
}

export function DashboardsAPI_RouteBuilder(_arg, m) {
    return `/api/dashboards/${m}`;
}

