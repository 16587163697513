import { unwrap, defaultArg, map as map_1, some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { toString, FSharpRef, Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Response_RouteInterpolatedForecast, Response_SessionParamModelSetup_$reflection, Response_SessionState_$reflection, Response_SessionInterpolatedForecast_$reflection, Response_RouteWaypoint_$reflection, Response_RouteInterpolatedForecast_$reflection, Response_SessionDetail_$reflection } from "../StenaWeather.WeatherServiceUI.Shared/SessionDetails/API.js";
import { ServerError_$reflection } from "../StenaWeather.WeatherServiceUI.Shared/Errors.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { getRecordElements, name as name_25, getUnionFields, getRecordField, array_type, option_type, string_type, record_type, bool_type, tuple_type, list_type, class_type, union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { isEmpty, head as head_1, last, item as item_1, mapIndexed, filter, tryLast, minBy, fold, tryFind, where, empty, ofArray, length, append, map as map_2, sortBy, tryHead, sortByDescending, toArray, singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { append as append_2, concat, find, map } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { List_groupBy } from "../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { utcNow, date as date_1, fromDate } from "../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { compare, equals } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { equals as equals_1, createObj, comparePrimitives, compare as compare_1, dateHash } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { PageTitle_title, ErrorView_Cmd_error, ErrorView_Cmd_ofError } from "../SharedView.js";
import { onSessionDetailsAPI, Cmd_OfAsync_eitherAsResult } from "../Server.js";
import { tryParse } from "../fable_modules/fable-library-js.4.19.3/Int32.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import SessionDetails$002Emodule from "../../src/StenaWeather.WeatherServiceUI.Client/Pages/SessionDetails.module.scss";
import { toText, format, printf, toFail, join } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { WeatherTypeModule_Parameter, WeatherTypeModule_ParameterModule_tryParse, WeatherTypeModule_declaringType, WeatherType_$reflection, WeatherTypeModule_ParameterModule_fromWeatherType, WeatherTypeModule_ParameterModule_metadata, WeatherTypeModule_ParameterModule_name } from "../StenaWeather.Domain/Weather.js";
import { DivsForStandardTable_timeDiv, rotateDirection180, DivsForStandardTable_defaultDiv } from "./Common.js";
import { TextModule_View } from "../Components/StenaWebUI/Core/Text.js";
import { FSharpMap__TryFind, ofList } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { WhiteSpaceType, UserSelectType, TextSize, TextVariant } from "../Components/StenaWebUI/Core/Text.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { DateTimeOffset_dateTimeStr, DateTimeOffset_TimeWithTimezone, DateTimeOffset_adjustFromContext, DateTimeOffset_dateStr, DateTimeOffset_DateTimeWithTimezone } from "../Components/TimeZones/Components.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { TooltipModule_View } from "../Components/StenaWebUI/Elements/Tooltip.js";
import { IconModule_View } from "../Components/StenaWebUI/Icons/Icon.js";
import { faTimes, faCheckCircle, faBan, faLongArrowAltUp, faCloud, faCloudSun, faSun, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { IndentModule_View } from "../Components/StenaWebUI/Core/Indent.js";
import { SpaceModule_View } from "../Components/StenaWebUI/Core/Space.js";
import { RowModule_View } from "../Components/StenaWebUI/Core/Row.js";
import { ButtonBase_ViewFlat, ButtonBase_ViewPrimary, ButtonBase_ViewSecondary } from "../Components/StenaWebUI/Elements/Button.js";
import { ButtonSize } from "../Components/StenaWebUI/Elements/Button.js";
import { parse } from "../fable_modules/fable-library-js.4.19.3/Double.js";
import { toArray as toArray_1, empty as empty_1, collect, singleton as singleton_1, append as append_1, delay, toList, head } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { PointModule_distance } from "../StenaWeather.Domain/Forecast.js";
import { HeadingModule_View } from "../Components/StenaWebUI/Core/Heading.js";
import { SeparatorLineModule_View } from "../Components/StenaWebUI/Core/SeparatorLine.js";
import { LabelModule_View } from "../Components/StenaWebUI/Elements/Label.js";
import { ColumnModule_View } from "../Components/StenaWebUI/Core/Column.js";
import { Popup_View } from "../Components/Leaflet/Popup.js";
import up_arrow from "../../src/StenaWeather.WeatherServiceUI.Client/images/up-arrow.svg";
import { bearing } from "leaflet-geometryutil";
import { Point_toPosition } from "../Components/Leaflet/Shared.js";
import { TileLayer } from "react-leaflet";
import { Polyline_View } from "../Components/Leaflet/Polyline.js";
import { Icon } from "leaflet";
import { Marker_View } from "../Components/Leaflet/Marker.js";
import { FeatureGroup_View } from "../Components/Leaflet/FeatureGroup.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { MapContainer_View } from "../Components/Leaflet/Container.js";
import { timeZoneContext } from "../Components/TimeZones/Context.js";
import { CollapsibleModule_View } from "../Components/StenaWebUI/Panels/Collapsible.js";
import { ChipModule_View } from "../Components/StenaWebUI/Elements/Chip.js";
import { BannerModule_View } from "../Components/StenaWebUI/Elements/Banner.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { BreadCrumbsModule_View, CrumbModule_View, ReactRouterCrumb_View } from "../Components/StenaWebUI/Elements/Breadcrumbs.js";
import { SwitchWithLabelModule_View } from "../Components/StenaWebUI/Forms/SwitchWithLabel.js";
import { StandardTableModule_view } from "../Components/StenaWebUI/Grid/StandardTable.js";
import { DrawerModule_view } from "../Components/StenaWebUI/Modal/Drawer.js";
import { LoadingScreenModule_View } from "../Components/StenaWebUI/Panels/LoadingScreen.js";

export function fromTryMethod(f, x) {
    const patternInput = f(x);
    if (patternInput[0]) {
        return some(patternInput[1]);
    }
    else {
        return undefined;
    }
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoadSessionDetail", "OnSessionDetailLoaded", "LoadSessionResponse", "OnSessionResponseLoaded", "OnDisplayWayPointOnMap", "OnCloseDisplayedWaypoint", "ShowSessionHistory", "HideSessionHistory", "ChangeWindDirectionUnits", "ToggleSessionHistoryItem"];
    }
}

export function Msg_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.SessionDetails.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Response_SessionDetail_$reflection(), ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", Response_SessionDetail_$reflection()]], [["ErrorValue", ServerError_$reflection()]]])]], [["Item", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Response_RouteInterpolatedForecast_$reflection(), ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", Response_RouteInterpolatedForecast_$reflection()]], [["ErrorValue", ServerError_$reflection()]]])]], [["Item", tuple_type(Response_RouteWaypoint_$reflection(), list_type(Response_SessionInterpolatedForecast_$reflection()))]], [], [], [], [["Item", bool_type]], [["Item", class_type("System.DateTimeOffset")]]]);
}

export class Forecast extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Loading", "Unknown", "Loaded"];
    }
}

export function Forecast_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.SessionDetails.Forecast", [], Forecast, () => [[], [], [["Item", Response_RouteInterpolatedForecast_$reflection()]]]);
}

export class SessionHistoryDay extends Record {
    constructor(Date$, IsCollapsed, Sessions) {
        super();
        this.Date = Date$;
        this.IsCollapsed = IsCollapsed;
        this.Sessions = Sessions;
    }
}

export function SessionHistoryDay_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.SessionDetails.SessionHistoryDay", [], SessionHistoryDay, () => [["Date", class_type("System.DateTimeOffset")], ["IsCollapsed", bool_type], ["Sessions", list_type(Response_SessionState_$reflection())]]);
}

export class SessionDetail extends Record {
    constructor(VesselName, CreatedAt, Parameters, Waypoints) {
        super();
        this.VesselName = VesselName;
        this.CreatedAt = CreatedAt;
        this.Parameters = Parameters;
        this.Waypoints = Waypoints;
    }
}

export function SessionDetail_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.SessionDetails.SessionDetail", [], SessionDetail, () => [["VesselName", string_type], ["CreatedAt", class_type("System.DateTimeOffset")], ["Parameters", list_type(Response_SessionParamModelSetup_$reflection())], ["Waypoints", list_type(Response_RouteWaypoint_$reflection())]]);
}

export class State extends Record {
    constructor(SessionId, Detail, SessionHistory, SelectedForecast, WaypointForecastToDisplay, Sidebar, UseNauticalUnits) {
        super();
        this.SessionId = SessionId;
        this.Detail = Detail;
        this.SessionHistory = SessionHistory;
        this.SelectedForecast = SelectedForecast;
        this.WaypointForecastToDisplay = WaypointForecastToDisplay;
        this.Sidebar = Sidebar;
        this.UseNauticalUnits = UseNauticalUnits;
    }
}

export function State_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.SessionDetails.State", [], State, () => [["SessionId", class_type("System.Guid")], ["Detail", option_type(SessionDetail_$reflection())], ["SessionHistory", array_type(SessionHistoryDay_$reflection())], ["SelectedForecast", Forecast_$reflection()], ["WaypointForecastToDisplay", option_type(tuple_type(Response_RouteWaypoint_$reflection(), list_type(Response_SessionInterpolatedForecast_$reflection())))], ["Sidebar", bool_type], ["UseNauticalUnits", bool_type]]);
}

export function init(i) {
    return [new State(i, undefined, new Array(0), new Forecast(1, []), undefined, false, true), singleton((dispatch) => {
        dispatch(new Msg(0, []));
    })];
}

function groupSessionsByDay(sessions) {
    return map((tupledArg) => (new SessionHistoryDay(tupledArg[0], true, tupledArg[1])), toArray(sortByDescending((tuple) => tuple[0], List_groupBy((s) => fromDate(date_1(s.CreatedAt)), sessions, {
        Equals: equals,
        GetHashCode: dateHash,
    }), {
        Compare: compare,
    })));
}

function selectedIsOpen(lastDay, days) {
    if (lastDay == null) {
        return days;
    }
    else {
        const day = lastDay;
        return map((d) => {
            if (equals(d.Date, day)) {
                return new SessionHistoryDay(d.Date, false, d.Sessions);
            }
            else {
                return d;
            }
        }, days);
    }
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const details = msg.fields[0];
            if (details.tag === 1) {
                return [state, ErrorView_Cmd_ofError(details.fields[0])];
            }
            else {
                const detail = details.fields[0];
                const lastItem = tryHead(sortByDescending((t) => t.CreatedAt, detail.SessionStates, {
                    Compare: compare,
                }));
                const lastGuid = map_1((t_1) => t_1.Token, lastItem);
                const viewDetail = new SessionDetail(detail.VesselName, detail.CreatedAt, detail.Parameters, detail.Waypoints);
                const sessionHistory = selectedIsOpen(map_1((t_2) => fromDate(date_1(t_2.CreatedAt)), lastItem), groupSessionsByDay(detail.SessionStates));
                if (lastGuid != null) {
                    const g = lastGuid;
                    return [new State(state.SessionId, viewDetail, sessionHistory, state.SelectedForecast, state.WaypointForecastToDisplay, state.Sidebar, state.UseNauticalUnits), singleton((dispatch) => {
                        dispatch(new Msg(2, [g]));
                    })];
                }
                else {
                    return [new State(state.SessionId, viewDetail, sessionHistory, new Forecast(1, []), state.WaypointForecastToDisplay, state.Sidebar, state.UseNauticalUnits), ErrorView_Cmd_error("No token returned from server")];
                }
            }
        }
        case 2:
            return [new State(state.SessionId, state.Detail, state.SessionHistory, new Forecast(0, []), state.WaypointForecastToDisplay, state.Sidebar, state.UseNauticalUnits), Cmd_OfAsync_eitherAsResult(() => onSessionDetailsAPI((x_2) => x_2.GetSessionResponseDetail(msg.fields[0])), (Item_1) => (new Msg(3, [Item_1])))];
        case 3: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                return [state, ErrorView_Cmd_ofError(response.fields[0])];
            }
            else {
                const r = response.fields[0];
                return [new State(state.SessionId, state.Detail, state.SessionHistory, new Forecast(2, [new Response_RouteInterpolatedForecast(r.Token, sortBy((tupledArg) => fromTryMethod((arg) => {
                    let outArg = 0;
                    return [tryParse(arg, 511, false, 32, new FSharpRef(() => outArg, (v) => {
                        outArg = (v | 0);
                    })), outArg];
                }, tupledArg[0].Reference), r.Forecasts, {
                    Compare: compare_1,
                }))]), state.WaypointForecastToDisplay, state.Sidebar, state.UseNauticalUnits), Cmd_none()];
            }
        }
        case 4:
            return [new State(state.SessionId, state.Detail, state.SessionHistory, state.SelectedForecast, msg.fields[0], state.Sidebar, state.UseNauticalUnits), Cmd_none()];
        case 5:
            return [new State(state.SessionId, state.Detail, state.SessionHistory, state.SelectedForecast, undefined, state.Sidebar, state.UseNauticalUnits), Cmd_none()];
        case 6:
            return [new State(state.SessionId, state.Detail, state.SessionHistory, state.SelectedForecast, state.WaypointForecastToDisplay, true, state.UseNauticalUnits), Cmd_none()];
        case 7:
            return [new State(state.SessionId, state.Detail, state.SessionHistory, state.SelectedForecast, state.WaypointForecastToDisplay, false, state.UseNauticalUnits), Cmd_none()];
        case 8:
            return [new State(state.SessionId, state.Detail, state.SessionHistory, state.SelectedForecast, state.WaypointForecastToDisplay, state.Sidebar, msg.fields[0]), Cmd_none()];
        case 9:
            return [new State(state.SessionId, state.Detail, map((d) => {
                if (equals(d.Date, msg.fields[0])) {
                    return new SessionHistoryDay(d.Date, !d.IsCollapsed, d.Sessions);
                }
                else {
                    return d;
                }
            }, state.SessionHistory), state.SelectedForecast, state.WaypointForecastToDisplay, state.Sidebar, state.UseNauticalUnits), Cmd_none()];
        default:
            return [state, Cmd_OfAsync_eitherAsResult(() => onSessionDetailsAPI((x) => x.GetSessionDetails(state.SessionId)), (Item) => (new Msg(1, [Item])))];
    }
}

export const cn = (() => {
    const styles = SessionDetails$002Emodule;
    const cnFn = (name) => (styles[name]);
    const cnFns = (names) => map_2((x) => (styles[x]), names);
    return {
        className: cnFn,
        classes: cnFns,
        prop: {
            className: (arg) => ["className", cnFn(arg)],
            classes: (arg_1) => ["className", join(" ", cnFns(arg_1))],
        },
    };
})();

export class ColumnName extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Coordinates", "Ref", "Time", "Model", "Direction", "Speed", "ShowOnMapButton", "Height", "Period", "Icon", "Sealevel", "Coverage", "Temperature", "Precipitation", "Category", "Code"];
    }
}

export function ColumnName_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.SessionDetails.ColumnName", [], ColumnName, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function ColumnNameModule_fromValue(str) {
    switch (str) {
        case "Coordinates":
            return new ColumnName(0, []);
        case "Ref":
            return new ColumnName(1, []);
        case "Time":
            return new ColumnName(2, []);
        case "Model":
            return new ColumnName(3, []);
        case "Direction":
            return new ColumnName(4, []);
        case "Speed":
            return new ColumnName(5, []);
        case "ShowOnMapButton":
            return new ColumnName(6, []);
        case "Height":
            return new ColumnName(7, []);
        case "Period":
            return new ColumnName(8, []);
        case "Icon":
            return new ColumnName(9, []);
        case "Sealevel":
            return new ColumnName(10, []);
        case "Coverage":
            return new ColumnName(11, []);
        case "Temperature":
            return new ColumnName(12, []);
        case "Precipitation":
            return new ColumnName(13, []);
        case "Category":
            return new ColumnName(14, []);
        case "Code":
            return new ColumnName(15, []);
        default:
            return toFail(printf("Cannot recognize value %A as valid ColumnName"))(str);
    }
}

export function ColumnNameModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Ref";
        case 2:
            return "Time";
        case 3:
            return "Model";
        case 4:
            return "Direction";
        case 5:
            return "Speed";
        case 6:
            return "ShowOnMapButton";
        case 7:
            return "Height";
        case 8:
            return "Period";
        case 9:
            return "Icon";
        case 10:
            return "Sealevel";
        case 11:
            return "Coverage";
        case 12:
            return "Temperature";
        case 13:
            return "Precipitation";
        case 14:
            return "Category";
        case 15:
            return "Code";
        default:
            return "Coordinates";
    }
}

function getColumnHeadersWithColumnOrder(detail) {
    return append(singleton(["waypoint", [new ColumnName(1, []), new ColumnName(0, []), new ColumnName(2, []), new ColumnName(6, [])]]), map_2((f) => [WeatherTypeModule_ParameterModule_name(f.Parameter), toArray(append(map_2((m) => ColumnNameModule_fromValue(m.Name), WeatherTypeModule_ParameterModule_metadata(f.Parameter)), singleton(new ColumnName(3, []))))], detail.Parameters));
}

export class ProcessingStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Processed", "Partial", "NotProcessed"];
    }
}

export function ProcessingStatus_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.SessionDetails.ProcessingStatus", [], ProcessingStatus, () => [[], [], []]);
}

function getStatus(pars, fc) {
    const parsLength = length(pars) | 0;
    const howMany = length(fc) | 0;
    if (howMany === parsLength) {
        return new ProcessingStatus(0, []);
    }
    else if ((howMany < parsLength) && (howMany > 0)) {
        return new ProcessingStatus(1, []);
    }
    else {
        return new ProcessingStatus(2, []);
    }
}

function DivsForStandardTable_modelNameAndCreatedDiv(name, created) {
    let children;
    const elm = ofArray([DivsForStandardTable_defaultDiv(name), (children = singleton(TextModule_View(ofList(map_2((value_3) => value_3, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(2, [])], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["color", "var(--lhds-color-ui-500)"], ["children", singleton(createElement(DateTimeOffset_DateTimeWithTimezone, {
        dateTime: created,
    }))]])), {
        Compare: comparePrimitives,
    }))), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))]);
    return createElement("div", {
        className: cn.className("modelNameCreated"),
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

function DivsForStandardTable_defaultDivWithTooltip(value, tooltipValue) {
    const children = singleton(TooltipModule_View(ofList(map_2((value_3) => value_3, ofArray([["label", tooltipValue], ["placement", "top"], ["children", singleton(TextModule_View(ofList(map_2((value_2) => value_2, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(1, [])], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", value]])), {
        Compare: comparePrimitives,
    })))]])), {
        Compare: comparePrimitives,
    })));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

function DivsForStandardTable_loadingDiv(value) {
    let t;
    const children = ofArray([IconModule_View(ofList(map_2((value_1) => value_1, ofArray([(t = faHourglassHalf, ["icon", t]), ["size", 14], ["color", "var(--lhds-color-ui-600)"]])), {
        Compare: comparePrimitives,
    })), TextModule_View(ofList(map_2((value_3) => value_3, ofArray([["variant", new TextVariant(3, [])], ["size", new TextSize(1, [])], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", value]])), {
        Compare: comparePrimitives,
    }))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

function DivsForStandardTable_cloudsDiv(value) {
    const p_1_3 = ofList(map_2((value_3) => value_3, ofArray([["num", 5], ["children", singleton(TooltipModule_View(ofList(map_2((value_2) => value_2, ofArray([["label", format('{0:' + "0.0000" + '}', value)], ["placement", "top"], ["children", singleton(IconModule_View(ofList(map_2((value_1) => value_1, ofArray([["icon", ((value >= 0) && (value <= 30)) ? faSun : (((value > 30) && (value < 60)) ? faCloudSun : faCloud)], ["color", "var(--lhds-color-ui-600)"], ["size", 25]])), {
        Compare: comparePrimitives,
    })))]])), {
        Compare: comparePrimitives,
    })))]])), {
        Compare: comparePrimitives,
    });
    return createElement(IndentModule_View, {
        p: p_1_3,
    });
}

function DivsForStandardTable_coordDiv(value, tooltipValue) {
    const children = singleton(TooltipModule_View(ofList(map_2((value_3) => value_3, ofArray([["label", tooltipValue], ["placement", "top"], ["children", singleton(TextModule_View(ofList(map_2((value_2) => value_2, ofArray([["variant", new TextVariant(3, [])], ["size", new TextSize(1, [])], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", value]])), {
        Compare: comparePrimitives,
    })))]])), {
        Compare: comparePrimitives,
    })));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

function DivsForStandardTable_directionsDiv(value, unit, useNauticalUnits) {
    let p_1_5, p_1_3, elems, t_7;
    const computedValue = !useNauticalUnits ? value : rotateDirection180(value);
    const p_1_6 = ofList(map_2((value_12) => value_12, singleton(["children", singleton((p_1_5 = ofList(map_2((value_11) => value_11, singleton(["children", ofArray([TooltipModule_View(ofList(map_2((value_4) => value_4, ofArray([["label", format('{0:' + "0.0000" + '}', computedValue)], ["placement", "top"], ["children", singleton(TextModule_View(ofList(map_2((value_3) => value_3, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(1, [])], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", `${format('{0:' + "0" + '}', computedValue)} ${unit}`]])), {
        Compare: comparePrimitives,
    })))]])), {
        Compare: comparePrimitives,
    })), (p_1_3 = ofList(map_2((value_5) => value_5, empty()), {
        Compare: comparePrimitives,
    }), createElement(SpaceModule_View, {
        p: p_1_3,
    })), createElement("div", createObj(ofArray([["style", {
        transform: ("rotate(" + ~~value) + "deg)",
    }], (elems = [IconModule_View(ofList(map_2((value_9) => value_9, ofArray([(t_7 = faLongArrowAltUp, ["icon", t_7]), ["color", "var(--lhds-color-ui-600)"]])), {
        Compare: comparePrimitives,
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])])))])])), {
        Compare: comparePrimitives,
    }), createElement(RowModule_View, {
        p: p_1_5,
    })))])), {
        Compare: comparePrimitives,
    });
    return createElement(IndentModule_View, {
        p: p_1_6,
    });
}

function DivsForStandardTable_waypointButton(onClickEvent) {
    const children = singleton(ButtonBase_ViewSecondary(ofList(map_2((value) => value, ofArray([["label", "Show on map"], ["size", new ButtonSize(1, [])], ["id", "showOnMapButton"], ["onClick", onClickEvent]])), {
        Compare: comparePrimitives,
    })));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

function DivsForStandardTable_statusIcon(status) {
    let t_3, t_6, t;
    switch (status.tag) {
        case 1:
            return IconModule_View(ofList(map_2((value_1) => value_1, ofArray([(t_3 = faHourglassHalf, ["icon", t_3]), ["size", 16], ["color", "var(--lhds-color-orange-600"]])), {
                Compare: comparePrimitives,
            }));
        case 2:
            return IconModule_View(ofList(map_2((value_2) => value_2, ofArray([(t_6 = faBan, ["icon", t_6]), ["size", 16], ["color", "var(--lhds-color-red-600"]])), {
                Compare: comparePrimitives,
            }));
        default:
            return IconModule_View(ofList(map_2((value) => value, ofArray([(t = faCheckCircle, ["icon", t]), ["size", 16], ["color", "var(--lhds-color-green-600"]])), {
                Compare: comparePrimitives,
            }));
    }
}

function DivsForStandardTable_statusIconDiv(pars, p) {
    let p_1_1;
    const children = singleton((p_1_1 = ofList(map_2((value) => value, singleton(["children", singleton(DivsForStandardTable_statusIcon(getStatus(pars, p.item[1])))])), {
        Compare: comparePrimitives,
    }), createElement(IndentModule_View, {
        p: p_1_1,
    })));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

export function getUnitForParamType(weather, paramType) {
    const res = defaultArg(tryHead(map_2((f_1) => f_1.UnitOfMeasure, where((f) => (f.Name === paramType), WeatherTypeModule_ParameterModule_metadata(WeatherTypeModule_ParameterModule_fromWeatherType(weather))))), "");
    if (res.indexOf("degree") >= 0) {
        return "°";
    }
    else {
        return res;
    }
}

function getFloatValueByColumnName(propName, forecastWeatherType) {
    let copyOfStruct;
    return parse(toString(getRecordField(head(getUnionFields(forecastWeatherType, (copyOfStruct = forecastWeatherType, WeatherType_$reflection()))[1]), find((t) => (name_25(t) === propName), getRecordElements(WeatherTypeModule_declaringType(forecastWeatherType))))));
}

function getDivByColumnNameFloat(value, tooltip, unit, columnName, useNauticalUnits) {
    switch (columnName.tag) {
        case 4:
            return DivsForStandardTable_directionsDiv(value, "°", useNauticalUnits);
        case 11:
            return DivsForStandardTable_cloudsDiv(value);
        case 14:
        case 15:
            return DivsForStandardTable_defaultDivWithTooltip(`${format('{0:' + "0" + '}', value)}`, tooltip);
        default:
            return DivsForStandardTable_defaultDivWithTooltip(`${format('{0:' + "0.0" + '}', value)} ${unit}`, tooltip);
    }
}

function toGpsString(p) {
    const lonStr = format('{0:' + "0.000" + '}', p.Longitude);
    return `${format('{0:' + "0.000" + '}', p.Latitude)} ${(p.Latitude > 0) ? "N" : "S"}, ${lonStr} ${(p.Longitude > 0) ? "E" : "W"}`;
}

function getToolTip(value, forecastWeatherType) {
    switch (forecastWeatherType.tag) {
        case 11:
            return forecastWeatherType.fields[0].Description;
        case 12:
            return forecastWeatherType.fields[0].Description;
        default:
            return toString(value);
    }
}

function renderCell(dispatch, useNauticalUnits, p) {
    const patternInput = p.item;
    const waypoint = patternInput[0];
    const patternInput_1 = p.value;
    const paramName = patternInput_1[0];
    const columnName = patternInput_1[1];
    const weatherTypeOption = WeatherTypeModule_ParameterModule_tryParse(paramName);
    if (weatherTypeOption != null) {
        const weatherType = weatherTypeOption;
        const forecast = tryFind((f) => equals_1(WeatherTypeModule_ParameterModule_fromWeatherType(f.PointForecast.Weather), weatherType), patternInput[1]);
        const changeUnits = (equals_1(weatherType, new WeatherTypeModule_Parameter(0, [])) ? true : equals_1(weatherType, new WeatherTypeModule_Parameter(3, []))) && useNauticalUnits;
        if (forecast != null) {
            const f_1 = forecast;
            const forecastWeatherType = f_1.PointForecast.Weather;
            const unit = getUnitForParamType(forecastWeatherType, ColumnNameModule_value(columnName));
            switch (columnName.tag) {
                case 3:
                    return DivsForStandardTable_modelNameAndCreatedDiv(f_1.Source.Model.Name, f_1.Source.Model.Created);
                case 4: {
                    const value = getFloatValueByColumnName(ColumnNameModule_value(columnName), forecastWeatherType);
                    return getDivByColumnNameFloat(value, value.toString(), "°", columnName, changeUnits);
                }
                case 14:
                case 15: {
                    const value_2 = getFloatValueByColumnName(ColumnNameModule_value(columnName), forecastWeatherType);
                    return getDivByColumnNameFloat(value_2, getToolTip(value_2, forecastWeatherType), unit, columnName, false);
                }
                default: {
                    const value_3 = getFloatValueByColumnName(ColumnNameModule_value(columnName), forecastWeatherType);
                    return getDivByColumnNameFloat(value_3, value_3.toString(), unit, columnName, false);
                }
            }
        }
        else {
            return DivsForStandardTable_loadingDiv("");
        }
    }
    else {
        switch (columnName.tag) {
            case 1:
                return DivsForStandardTable_defaultDiv(waypoint.Reference);
            case 0:
                return DivsForStandardTable_coordDiv(toGpsString(waypoint.TimePoint.Point), `${waypoint.TimePoint.Point.Latitude}, ${waypoint.TimePoint.Point.Longitude}`);
            case 2:
                return DivsForStandardTable_timeDiv(waypoint.TimePoint.DateTime);
            case 6:
                return DivsForStandardTable_waypointButton((_arg) => {
                    dispatch(new Msg(4, [p.item]));
                });
            default:
                return DivsForStandardTable_defaultDiv(paramName);
        }
    }
}

function getStats(pars, fc) {
    const parsLength = length(pars) | 0;
    return fold((acc, item) => {
        const howMany = length(item[1]) | 0;
        if (howMany === parsLength) {
            return {
                Full: acc.Full + 1,
                Missing: acc.Missing,
                Partial: acc.Partial,
            };
        }
        else if ((howMany < parsLength) && (howMany > 0)) {
            return {
                Full: acc.Full,
                Missing: acc.Missing,
                Partial: acc.Partial + 1,
            };
        }
        else {
            return {
                Full: acc.Full,
                Missing: acc.Missing + 1,
                Partial: acc.Partial,
            };
        }
    }, {
        Full: 0,
        Missing: 0,
        Partial: 0,
    }, fc.Forecasts);
}

function ForecastMap_RouteInterpolatedForecast_closestWaypoint(fcs, p) {
    return minBy((tuple) => tuple[0], map_2((tupledArg) => {
        const r = tupledArg[0];
        return [PointModule_distance(r.TimePoint.Point, p), [r, tupledArg[1]]];
    }, fcs), {
        Compare: comparePrimitives,
    })[1];
}

function ForecastMap_getBounds(f) {
    const fxo = tryHead(f.Forecasts);
    const lxo = tryLast(f.Forecasts);
    let matchResult, fx, lx;
    if (fxo != null) {
        if (lxo != null) {
            matchResult = 0;
            fx = fxo;
            lx = lxo;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return map_2((x) => x.TimePoint.Point, ofArray([fx[0], lx[0]]));
        default:
            return empty();
    }
}

function ForecastMap_viewPoint(sessionDetails, wpToShow_, wpToShow__1, useNauticalUnits) {
    let children;
    const wpToShow = [wpToShow_, wpToShow__1];
    const statusToDisplay = getStatus(sessionDetails.Parameters, wpToShow[1]);
    const waypoint = wpToShow[0];
    const p_1_12 = ofList(map_2((value_20) => value_20, ofArray([["position", waypoint.TimePoint.Point], ["closeButton", true], ["closeOnClick", false], ["children", singleton((children = toList(delay(() => {
        let p_1_3, elm, elm_2, p_1_1, p_1_2;
        return append_1(singleton_1((p_1_3 = ofList(map_2((value_9) => value_9, singleton(["children", singleton((elm = ofArray([(elm_2 = ofArray([DivsForStandardTable_statusIcon(statusToDisplay), (p_1_1 = ofList(map_2((value_6) => value_6, empty()), {
            Compare: comparePrimitives,
        }), createElement(SpaceModule_View, {
            p: p_1_1,
        })), (p_1_2 = ofList(map_2((value_8) => value_8, ofArray([["children", singleton(`${toGpsString(waypoint.TimePoint.Point)} (WGS84)`)], ["variant", "h5"]])), {
            Compare: comparePrimitives,
        }), createElement(HeadingModule_View, {
            p: p_1_2,
        }))]), createElement("div", {
            className: cn.className("heading-icon"),
            children: reactApi.Children.toArray(Array.from(elm_2)),
        })), DivsForStandardTable_timeDiv(waypoint.TimePoint.DateTime)]), createElement("div", {
            className: cn.className("popup-header"),
            children: reactApi.Children.toArray(Array.from(elm)),
        })))])), {
            Compare: comparePrimitives,
        }), createElement(RowModule_View, {
            p: p_1_3,
        }))), delay(() => collect((pointForecast) => {
            let p_1_11, p_1_4, p_1_5, p_1_10;
            const modelPropertiesNames = map_2((f) => f.Name, WeatherTypeModule_ParameterModule_metadata(WeatherTypeModule_ParameterModule_fromWeatherType(pointForecast.PointForecast.Weather)));
            const param = WeatherTypeModule_ParameterModule_fromWeatherType(pointForecast.PointForecast.Weather);
            const paramName = WeatherTypeModule_ParameterModule_name(param);
            const changeUnits = (equals_1(param, new WeatherTypeModule_Parameter(0, [])) ? true : equals_1(param, new WeatherTypeModule_Parameter(3, []))) && useNauticalUnits;
            return singleton_1((p_1_11 = ofList(map_2((value_19) => value_19, singleton(["children", ofArray([(p_1_4 = ofList(map_2((value_11) => value_11, ofArray([["children", singleton(paramName)], ["variant", "h5"]])), {
                Compare: comparePrimitives,
            }), createElement(HeadingModule_View, {
                p: p_1_4,
            })), (p_1_5 = ofList(map_2((value_12) => value_12, singleton(["children", "100%"])), {
                Compare: comparePrimitives,
            }), createElement(SeparatorLineModule_View, {
                p: p_1_5,
            })), (p_1_10 = ofList(map_2((value_18) => value_18, singleton(["children", toList(delay(() => append_1(collect((propertyName) => {
                let p_1_6;
                const value_13 = getFloatValueByColumnName(propertyName, pointForecast.PointForecast.Weather);
                const unit = getUnitForParamType(pointForecast.PointForecast.Weather, propertyName);
                return append_1(singleton_1((p_1_6 = ofList(map_2((value_14) => value_14, ofArray([["text", propertyName], ["children", singleton(getDivByColumnNameFloat(value_13, getToolTip(value_13, pointForecast.PointForecast.Weather), unit, ColumnNameModule_fromValue(propertyName), changeUnits))]])), {
                    Compare: comparePrimitives,
                }), createElement(LabelModule_View, {
                    p: p_1_6,
                }))), delay(() => {
                    let p_1_7;
                    return singleton_1((p_1_7 = ofList(map_2((value_15) => value_15, empty()), {
                        Compare: comparePrimitives,
                    }), createElement(SpaceModule_View, {
                        p: p_1_7,
                    })));
                }));
            }, modelPropertiesNames), delay(() => {
                let p_1_8;
                return append_1(singleton_1((p_1_8 = ofList(map_2((value_16) => value_16, ofArray([["text", "Model"], ["children", singleton(DivsForStandardTable_modelNameAndCreatedDiv(pointForecast.Source.Model.Name, pointForecast.Source.Model.Created))]])), {
                    Compare: comparePrimitives,
                }), createElement(LabelModule_View, {
                    p: p_1_8,
                }))), delay(() => {
                    let p_1_9;
                    return singleton_1((p_1_9 = ofList(map_2((value_17) => value_17, singleton(["children", "100%"])), {
                        Compare: comparePrimitives,
                    }), createElement(SeparatorLineModule_View, {
                        p: p_1_9,
                    })));
                }));
            }))))])), {
                Compare: comparePrimitives,
            }), createElement(RowModule_View, {
                p: p_1_10,
            }))])])), {
                Compare: comparePrimitives,
            }), createElement(ColumnModule_View, {
                p: p_1_11,
            })));
        }, wpToShow[1])));
    })), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })))]])), {
        Compare: comparePrimitives,
    });
    return createElement(Popup_View, {
        p: p_1_12,
    });
}

function ForecastMap_viewInner(f, sd, wpToShow, useNauticalUnits, dispatch) {
    let elems, p_1_5, props, p_1_4, p_1_1, p_1_3, props_2, arg_3, arg_1_1, arg_2_1, arg_3_1;
    const closestPointIndex = map_1((tuple) => tuple[0], tryHead(filter((tupledArg_1) => (compare(tupledArg_1[1], utcNow()) > 0), mapIndexed((i, tupledArg) => [i, tupledArg[0].TimePoint.DateTime], f.Forecasts))));
    const currentLocation = (closestPointIndex != null) ? item_1(closestPointIndex, f.Forecasts)[0].TimePoint.Point : last(f.Forecasts)[0].TimePoint.Point;
    const url = up_arrow;
    const angle = bearing(Point_toPosition(head_1(f.Forecasts)[0].TimePoint.Point), Point_toPosition(last(f.Forecasts)[0].TimePoint.Point)) | 0;
    return createElement("div", createObj(ofArray([["className", cn.className("map")], ["style", {
        height: 1034 + "px",
    }], (elems = [(p_1_5 = ofList(map_2((value_15) => value_15, ofArray([["bounds", ForecastMap_getBounds(f)], ["scrollWheelZoom", true], ["children", ofArray([(props = {
        attribution: "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    }, react.createElement(TileLayer, props)), (p_1_4 = ofList(map_2((value_13) => value_13, ofArray([["onClick", (arg_1) => {
        dispatch(new Msg(4, [ForecastMap_RouteInterpolatedForecast_closestWaypoint(f.Forecasts, arg_1)]));
    }], ["children", ofArray([(p_1_1 = ofList(map_2((value_6) => value_6, ofArray([["color", "#2378CD"], ["weight", 5], ["dashArray", "5 10"], ["points", map_2((arg_2) => arg_2[0].TimePoint.Point, f.Forecasts)]])), {
        Compare: comparePrimitives,
    }), createElement(Polyline_View, {
        p: p_1_1,
    })), (p_1_3 = ofList(map_2((value_12) => value_12, ofArray([["position", currentLocation], ["rotationAngle", angle], ["rotationOrigin", "center center"], ["icon", (props_2 = ofArray([["iconUrl", url], ["iconSize", [24, 24]], ["iconAnchor", [12, 12]]]), (arg_3 = toString(map_1((x_4) => x_4, FSharpMap__TryFind(ofList(map_2((value_7) => value_7, props_2), {
        Compare: comparePrimitives,
    }), "iconUrl"))), (arg_1_1 = map_1((x_6) => x_6, FSharpMap__TryFind(ofList(map_2((value_9) => value_9, props_2), {
        Compare: comparePrimitives,
    }), "iconSize")), (arg_2_1 = map_1((x_8) => x_8, FSharpMap__TryFind(ofList(map_2((value_10) => value_10, props_2), {
        Compare: comparePrimitives,
    }), "iconAnchor")), (arg_3_1 = map_1((x_10) => x_10, FSharpMap__TryFind(ofList(map_2((value_11) => value_11, props_2), {
        Compare: comparePrimitives,
    }), "popupAnchor")), new Icon({iconUrl: arg_3, iconSize: arg_1_1, iconAnchor: arg_2_1, popupAnchor: arg_3_1}))))))]])), {
        Compare: comparePrimitives,
    }), createElement(Marker_View, {
        p: p_1_3,
    }))])]])), {
        Compare: comparePrimitives,
    }), createElement(FeatureGroup_View, {
        p: p_1_4,
    })), defaultArg(map_1((f_2) => ForecastMap_viewPoint(sd, f_2[0], f_2[1], useNauticalUnits), wpToShow), defaultOf())])]])), {
        Compare: comparePrimitives,
    }), createElement(MapContainer_View, {
        p: p_1_5,
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

function ForecastMap_view(state, dispatch) {
    const matchValue = state.Detail;
    const matchValue_1 = state.SelectedForecast;
    let matchResult, f, s;
    if (matchValue != null) {
        if (matchValue_1.tag === 2) {
            matchResult = 0;
            f = matchValue_1.fields[0];
            s = matchValue;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            if (length(f.Forecasts) > 0) {
                return ForecastMap_viewInner(f, s, state.WaypointForecastToDisplay, state.UseNauticalUnits, dispatch);
            }
            else {
                return defaultOf();
            }
        default:
            return defaultOf();
    }
}

export function StatelessCollapsible(statelessCollapsibleInputProps) {
    const children = statelessCollapsibleInputProps.children;
    const label = statelessCollapsibleInputProps.label;
    const onClickHandler = statelessCollapsibleInputProps.onClickHandler;
    const isCollapsed = statelessCollapsibleInputProps.isCollapsed;
    const p_1_1 = ofList(map_2((value) => value, ofArray([["label", DateTimeOffset_dateStr(DateTimeOffset_adjustFromContext(reactApi.useContext(timeZoneContext), label))], ["collapsed", isCollapsed], ["onClick", onClickHandler], ["children", children]])), {
        Compare: comparePrimitives,
    });
    return createElement(CollapsibleModule_View, {
        p: p_1_1,
    });
}

export function SessionHistory_SessionHistoryDayGroup(sessionHistory_SessionHistoryDayGroupInputProps) {
    const sessionsDay = sessionHistory_SessionHistoryDayGroupInputProps.sessionsDay;
    const selectedToken = sessionHistory_SessionHistoryDayGroupInputProps.selectedToken;
    const dispatch = sessionHistory_SessionHistoryDayGroupInputProps.dispatch;
    return createElement(StatelessCollapsible, {
        isCollapsed: sessionsDay.IsCollapsed,
        onClickHandler: (_arg) => {
            dispatch(new Msg(9, [sessionsDay.Date]));
        },
        label: sessionsDay.Date,
        children: map_2((s) => {
            let elems_1, elm;
            return createElement("div", createObj(ofArray([["onClick", (_arg_1) => {
                dispatch(new Msg(2, [s.Token]));
            }], ["className", cn.className("sessionHistoryItem")], (elems_1 = [createElement("div", {
                children: [s.Token],
            }), (elm = toList(delay(() => {
                let p_1_1;
                return append_1(equals_1(s.Token, selectedToken) ? singleton_1((p_1_1 = ofList(map_2((value_8) => value_8, ofArray([["variant", "secondary"], ["label", "selected"]])), {
                    Compare: comparePrimitives,
                }), createElement(ChipModule_View, {
                    p: p_1_1,
                }))) : empty_1(), delay(() => singleton_1(createElement("div", {
                    children: [createElement(DateTimeOffset_TimeWithTimezone, {
                        dateTime: s.CreatedAt,
                    })],
                }))));
            })), createElement("div", {
                className: cn.className("sessionHistoryItemRight"),
                children: reactApi.Children.toArray(Array.from(elm)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        }, sortByDescending((x) => x.CreatedAt, sessionsDay.Sessions, {
            Compare: compare,
        })),
    });
}

export function InfoBanners(waypointsCount, detail, forecast) {
    let p_1_1, p_1_2, p_1_3, p_1_4, p_1_5;
    const ctx = reactApi.useContext(timeZoneContext);
    let patternInput;
    switch (forecast.tag) {
        case 1: {
            patternInput = ["Unknown", "Loading", "Unknown", "Unknown", "Unknown"];
            break;
        }
        case 2: {
            const forecast_1 = forecast.fields[0];
            const matchValue = forecast_1.Forecasts;
            if (isEmpty(matchValue)) {
                patternInput = ["Unknown", "Unknown", "Unknown", "Unknown", "Unknown"];
            }
            else {
                const forecasts = matchValue;
                const firstForecast = head_1(forecasts)[0];
                const lastForecast = last(forecasts)[0];
                const created = `${DateTimeOffset_dateTimeStr(DateTimeOffset_adjustFromContext(ctx, firstForecast.TimePoint.DateTime))}`;
                const forecastRange = `${DateTimeOffset_dateTimeStr(DateTimeOffset_adjustFromContext(ctx, firstForecast.TimePoint.DateTime))} - ${DateTimeOffset_dateTimeStr(DateTimeOffset_adjustFromContext(ctx, lastForecast.TimePoint.DateTime))}`;
                const stats = getStats(detail.Parameters, forecast_1);
                patternInput = [created, forecastRange, `${stats.Full}/${waypointsCount}`, `${stats.Partial}/${waypointsCount}`, `${stats.Missing}/${waypointsCount}`];
            }
            break;
        }
        default:
            patternInput = ["Loading", "Loading", "Loading", "Loading", "Loading"];
    }
    const elm = ofArray([createElement("div", {
        className: cn.className("box"),
        children: (p_1_1 = ofList(map_2((value_7) => value_7, ofArray([["headerText", "Session Initiated Date"], ["variant", "info"], ["text", patternInput[0]]])), {
            Compare: comparePrimitives,
        }), createElement(BannerModule_View, {
            p: p_1_1,
        })),
    }), createElement("div", {
        className: cn.className("box"),
        children: (p_1_2 = ofList(map_2((value_12) => value_12, ofArray([["headerText", "Session time range"], ["variant", "info"], ["text", patternInput[1]]])), {
            Compare: comparePrimitives,
        }), createElement(BannerModule_View, {
            p: p_1_2,
        })),
    }), createElement("div", {
        className: cn.className("box"),
        children: (p_1_3 = ofList(map_2((value_17) => value_17, ofArray([["headerText", "Processed Requests"], ["variant", "success"], ["text", patternInput[2]]])), {
            Compare: comparePrimitives,
        }), createElement(BannerModule_View, {
            p: p_1_3,
        })),
    }), createElement("div", {
        className: cn.className("box"),
        children: (p_1_4 = ofList(map_2((value_22) => value_22, ofArray([["icon", faHourglassHalf], ["headerText", "Partially Processed"], ["variant", "warning"], ["text", patternInput[3]]])), {
            Compare: comparePrimitives,
        }), createElement(BannerModule_View, {
            p: p_1_4,
        })),
    }), createElement("div", {
        className: cn.className("box"),
        children: (p_1_5 = ofList(map_2((value_27) => value_27, ofArray([["icon", faBan], ["headerText", "Not Processed"], ["variant", "error"], ["text", patternInput[4]]])), {
            Compare: comparePrimitives,
        }), createElement(BannerModule_View, {
            p: p_1_5,
        })),
    })]);
    return createElement("div", {
        className: cn.className("boxes"),
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

export function SessionDetailsView(sessionDetailsViewInputProps) {
    let f, p_1_4, p_1_2, p_1_3, elm_2, elm_4, p_1_5, elm_8, p_1_7, p_1_9, elm_14, elm_16, elm_18;
    const sessionId = sessionDetailsViewInputProps.sessionId;
    let patternInput;
    const init_1 = init(sessionId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [sessionId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const matchValue = state_1.Detail;
    if (matchValue != null) {
        const detail = matchValue;
        const groupsAndColumns = getColumnHeadersWithColumnOrder(detail);
        const tableItems = defaultArg((f = state_1.SelectedForecast, (f.tag === 1) ? undefined : ((f.tag === 2) ? f.fields[0].Forecasts : undefined)), empty());
        const waypointsCount = length(detail.Waypoints) | 0;
        const elm = ofArray([(p_1_4 = ofList(map_2((value_7) => value_7, singleton(["children", ofArray([(p_1_2 = ofList(map_2((value_5) => value_5, ofArray([["label", "List of vessels"], ["dest", "/vessels"]])), {
            Compare: comparePrimitives,
        }), createElement(ReactRouterCrumb_View, {
            p: p_1_2,
        })), (p_1_3 = ofList(map_2((value_6) => value_6, singleton(["label", "Session details"])), {
            Compare: comparePrimitives,
        }), createElement(CrumbModule_View, {
            p: p_1_3,
        }))])])), {
            Compare: comparePrimitives,
        }), createElement(BreadCrumbsModule_View, {
            p: p_1_4,
        })), (elm_2 = ofArray([(elm_4 = ofArray([PageTitle_title(`Session Details - ${detail.VesselName}`, "See all details for existing waypoints"), createElement("div", {
            className: cn.className("historyButton"),
            children: (p_1_5 = ofList(map_2((value_18) => value_18, ofArray([["label", "Show session history"], ["size", new ButtonSize(0, [])], ["onClick", (_arg_2) => {
                dispatch(new Msg(6, []));
            }]])), {
                Compare: comparePrimitives,
            }), createElement(ButtonBase_ViewPrimary, {
                p: p_1_5,
            })),
        })]), createElement("div", {
            className: cn.className("heading-wrapper"),
            children: reactApi.Children.toArray(Array.from(elm_4)),
        })), InfoBanners(waypointsCount, detail, state_1.SelectedForecast), (elm_8 = ofArray([TextModule_View(ofList(map_2((value_23) => value_23, singleton(["children", "Scientific"])), {
            Compare: comparePrimitives,
        })), createElement("div", {
            className: cn.className("wind-switch"),
            children: (p_1_7 = ofList(map_2((value_28) => value_28, ofArray([["label", "Nautical"], ["onValueChange", (x_6) => {
                dispatch(new Msg(8, [x_6]));
            }], ["value", state_1.UseNauticalUnits]])), {
                Compare: comparePrimitives,
            }), createElement(SwitchWithLabelModule_View, {
                p: p_1_7,
            })),
        })]), createElement("div", {
            className: cn.className("label-switch-div"),
            children: reactApi.Children.toArray(Array.from(elm_8)),
        })), createElement("div", {
            className: cn.className("table"),
            children: StandardTableModule_view(createObj(toList([["items", toArray_1(toArray(tableItems))], ["variant", "relaxed"], ["loading", equals_1(state_1.SelectedForecast, new Forecast(0, []))], ["config", createObj(toList(toList(delay(() => append_1(singleton_1(["keyResolver", (tupledArg) => tupledArg[0].Reference]), delay(() => append_1(singleton_1(["rowBackgroundResolver", (tupledArg_1) => {
                const status = getStatus(detail.Parameters, tupledArg_1[1]);
                switch (status.tag) {
                    case 1:
                        return {
                            background: "var(--lhds-color-orange-50)",
                        };
                    case 2:
                        return {
                            background: "var(--lhds-color-red-50)",
                        };
                    default:
                        return {
                            background: "var(--lhds-color-green-50)",
                        };
                }
            }]), delay(() => append_1(singleton_1(["stickyHeader", true]), delay(() => append_1(singleton_1(["stickyColumnGroups", "first"]), delay(() => append_1(singleton_1(["disableSorting", true]), delay(() => append_1(singleton_1(["columns", createObj(toList(delay(() => {
                let columnProps;
                return append_1(singleton_1((columnProps = createObj(toList([["name", `waypoint.${ColumnNameModule_value(new ColumnName(9, []))}`], ["itemValueResolver", (_arg_6) => ["waypoint", new ColumnName(9, [])]], ["columnLabel", ""], ["width", toText(`${80}px`)], ["renderCell", (p_14) => DivsForStandardTable_statusIconDiv(detail.Parameters, p_14)]])), [columnProps.name, columnProps])), delay(() => collect((matchValue_1) => {
                    let columnProps_1;
                    const group = matchValue_1[0];
                    const column = matchValue_1[1];
                    return singleton_1((columnProps_1 = createObj(toList([["name", `${group}.${ColumnNameModule_value(column)}`], ["itemValueResolver", (_arg_8) => [group, column]], ["columnLabel", equals_1(column, new ColumnName(0, [])) ? "Coordinates (WGS84)" : ColumnNameModule_value(column)], ["width", toText(`${(equals_1(column, new ColumnName(2, [])) ? true : equals_1(column, new ColumnName(1, []))) ? 80 : 130}px`)], ["renderCell", (p_15) => renderCell(dispatch, state_1.UseNauticalUnits, p_15)]])), [columnProps_1.name, columnProps_1]));
                }, concat(map_2((f_1) => map((s) => [f_1[0], s], f_1[1]), groupsAndColumns)))));
            })))]), delay(() => append_1(singleton_1(["columnGroups", createObj(toList(delay(() => collect((matchValue_2) => {
                let groupProps;
                const group_1 = matchValue_2[0];
                const columns_1 = matchValue_2[1];
                return singleton_1((groupProps = createObj(toList([["label", group_1], ["name", group_1], ["borderLeft", true], ["columnOrder", map((c) => (`${group_1}.${c}`), (group_1 === "waypoint") ? append_2([new ColumnName(9, [])], columns_1) : columns_1)]])), [groupProps.name, groupProps]));
            }, groupsAndColumns))))]), delay(() => singleton_1(["columnGroupOrder", toArray(map_2((f_2) => f_2[0], groupsAndColumns))])))))))))))))))))))]]))),
        })]), createElement("div", {
            className: cn.className("upperPart"),
            children: reactApi.Children.toArray(Array.from(elm_2)),
        })), DrawerModule_view(ofList(map_2((value_45) => value_45, ofArray([["isOpen", state_1.Sidebar], ["slideFrom", "right"], ["width", `${477}px`], ["zIndex", 100], ["background", "white"], ["onRequestClose", () => {
            dispatch(new Msg(7, []));
        }], ["children", singleton((p_1_9 = ofList(map_2((value_44) => value_44, singleton(["children", singleton((elm_14 = ofArray([(elm_16 = ofArray([createElement("div", {
            children: ["Session history"],
        }), ButtonBase_ViewFlat(ofList(map_2((value_40) => value_40, ofArray([["onClick", (_arg_11) => {
            dispatch(new Msg(7, []));
        }], ["leftIcon", faTimes]])), {
            Compare: comparePrimitives,
        }))]), createElement("div", {
            className: cn.className("sessionHistoryHeader"),
            children: reactApi.Children.toArray(Array.from(elm_16)),
        })), (elm_18 = toList(delay(() => {
            let xs_10;
            let currentToken;
            const x_9 = state_1.SelectedForecast;
            currentToken = ((x_9.tag === 2) ? x_9.fields[0].Token : undefined);
            return singleton_1((xs_10 = map((s_1) => createElement(SessionHistory_SessionHistoryDayGroup, {
                dispatch: dispatch,
                selectedToken: unwrap(currentToken),
                sessionsDay: s_1,
            }), state_1.SessionHistory), react.createElement(react.Fragment, {}, ...xs_10)));
        })), createElement("div", {
            className: cn.className("sessionHistoryList"),
            children: reactApi.Children.toArray(Array.from(elm_18)),
        }))]), createElement("div", {
            className: cn.className("sessionHistory"),
            children: reactApi.Children.toArray(Array.from(elm_14)),
        })))])), {
            Compare: comparePrimitives,
        }), createElement(ColumnModule_View, {
            p: p_1_9,
        })))]])), {
            Compare: comparePrimitives,
        })), ForecastMap_view(state_1, dispatch)]);
        return createElement("div", {
            className: cn.className("sessionDetails"),
            children: reactApi.Children.toArray(Array.from(elm)),
        });
    }
    else {
        const p_1_1 = ofList(map_2((value) => value, singleton(["text", "Loading ..."])), {
            Compare: comparePrimitives,
        });
        return createElement(LoadingScreenModule_View, {
            p: p_1_1,
        });
    }
}

